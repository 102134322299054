<div class="container">

  <div class="message-box" *ngIf="chatMessage">

    <div class="username">
      {{chatMessage.user.username}}
    </div>

    <div class="profile-photo">
      <img *ngIf="chatMessage.user.profilePhoto" [src]="chatMessage.user.profilePhoto" width="50" height="50">
    </div>

    <p>{{chatMessage.message}}</p>
  </div>

</div>
