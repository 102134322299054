import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { WsService } from "../services/ws.service";
import { Subject, take, takeUntil } from "rxjs";
import { IMessage } from "../models/message.model";
import { EOverlayEventType } from "../enums/overlay-event-type.enum";

@Component({
  selector: 'app-chat-overlay',
  templateUrl: './chat-overlay.component.html',
  styleUrls: ['./chat-overlay.component.css']
})
export class ChatOverlayComponent implements OnInit, OnDestroy {
  private authKey: string = '';
  private notFound = false;
  chatMessage?: IMessage;
  destroyed$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private wsService: WsService,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(r => {
      if (!r.has('key')) {
        this.notFound = true;
        return;
      }

      this.authKey = r.get('key') as string;
      this.connect();
    });
  }

  connect() {
    this.wsService.connect(this.authKey);
    this.wsService.onConnect.pipe(take(1), takeUntil(this.destroyed$))
      .subscribe(r => {
        this.listenOverlayEvents();
      });

    this.wsService.onDisconnect.pipe(takeUntil(this.destroyed$))
      .subscribe(r => {

      });
  }

  listenOverlayEvents() {
    this.wsService.subscribeOverlay()?.pipe(takeUntil(this.destroyed$))
      .subscribe(r => {
        switch (r.event) {
          case EOverlayEventType.ShowMessage:
            this.showMessage(r.data);
            break;
          case EOverlayEventType.ClearMessage:
            this.clearMessage();
            break;
        }
      });
  }

  showMessage(data: IMessage) {
    this.chatMessage = data;
    if (data.delay > 0) {
      setTimeout(() => {
        this.clearMessage();
      }, data.delay * 1000);
    }
  }

  clearMessage() {
    this.chatMessage = undefined;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
