import { Injectable } from "@angular/core";
import { Socket } from 'ngx-socket-io';
import { environment } from "../../environments/environment";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class WsService {
  socket?: Socket;

  private onConnectSubject = new BehaviorSubject({});
  onConnect = this.onConnectSubject.asObservable().pipe();

  private onDisconnectSubject = new BehaviorSubject({});
  onDisconnect = this.onDisconnectSubject.asObservable().pipe();

  constructor() {
  }

  connect(token: string) {
    this.socket = new Socket({
      url: environment.wsUrl,
      options: {
        path: '/live-stream/socket.io',
        autoConnect: false,
        extraHeaders: {
          'publisher-authorization': token,
        }
      }
    });

    this.socket.connect();

    this.socket.on('connect', (r: any) => {
      this.onConnectSubject.next(r)
    });
    this.socket.on('disconnect', (r: any) => {
      this.onDisconnectSubject.next(r)
    });
  }

  subscribeOverlay(): Observable<any> | undefined {
    return this.socket?.fromEvent('overlay');
  }

  disconnect(): void {
    try {
      if (this.socket) {
        this.socket.disconnect();
      }

    } catch (e) {

    }
  }
}
