import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { RouterModule } from "@angular/router";
import { SocketIoModule } from "ngx-socket-io";
import { WsService } from "./services/ws.service";
import { ChatOverlayComponent } from './chat-overlay/chat-overlay.component';

@NgModule({
  declarations: [
    AppComponent,
    ChatOverlayComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([
      {
        path: 'overlay',
        component: ChatOverlayComponent,
      }
    ]),
    SocketIoModule
  ],
  providers: [
    WsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
